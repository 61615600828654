/* -Callout block
---------------------------------------------------------------------------- */
.callout-block {
  @include section-padding(2);
  @include large-content-text;

  &.callout-block--background {
    background-position: 50% 0;
    background-size: cover;
    background-repeat: no-repeat;

    .btn,
    a.btn,
    button {
      @include button-secondary;
    }
    &.callout-block--sea-dark,
    &.callout-block--pine-dark,
    &.callout-block--earth-dark,
    &.callout-block--fir-dark,
    &.callout-block--lake-dark {
      color: #fff;
    }
  }

  .section-block & {
    @include section-padding;

    background-image: none !important;
  }
}

.callout-block-content {
  text-align: center;

  > *:last-child {
    margin-bottom: 0 !important;
  }
  .contact-person {
    margin-bottom: 1em;
  }
  .link-block {
    margin-right: auto;
    margin-left: auto;
  }
}
