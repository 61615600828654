/* -'Facts and text' block
-----------------------------------------------------------------------------*/
.facts-and-text-block {
  @include section-margin;
}

.facts-and-text-block-content {
  p {
    @extend %lead-text;

    @include media-max($breakpoint-type-small) {
      font-size: rem($base-font-size + 4px) !important;
    }
  }
}

.facts-and-text-block-facts {
  margin-top: 50px;
}

.facts-and-text-block-fact {
  display: flex;
  flex-direction: row-reverse;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
.facts-and-text-block-fact-content {
  flex: 1 1 85%;
  margin-left: 20px;
}

.facts-and-text-block-fact-icon {
  flex: 1 1 15%;
}

@include media-min(rem(960px)) {
  .facts-and-text-block-inner {
    display: flex;
  }
  .facts-and-text-block-content {
    margin-right: 60px;
    width: 60%;
  }
  .facts-and-text-block-facts {
    margin-top: 0;
    width: 40%;
  }
}

@include media-min(rem(1050px)) {
  .facts-and-text-block-content {
    margin-right: 100px;
  }
}
