/* -Into image with content
---------------------------------------------------------------------------- */
$intro-content-padding: 50px;

// Keep duration in sync with Intro.js
$intro-animation-duration: 4000ms;
$intro-animation-pan: 2%;
$intro-animation-scale: 1.1;

$initial-intro-transform: translate(0, 0) scale(1);
@keyframes intro-top-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-top-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, -$intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-right {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate($intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}
@keyframes intro-bottom-left {
  0% {
    transform: $initial-intro-transform;
  }
  100% {
    transform: translate(-$intro-animation-pan, $intro-animation-pan)
      scale($intro-animation-scale);
  }
}

// Must have a wrapping column flex container for IE11
// https://github.com/philipwalton/flexbugs#flexbug-3
.intro-container {
  display: flex;
  flex-direction: column;
}

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-height: 700px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  .page-action-color--sea-light &,
  .page-action-color--sea-dark &,
  .page-action-color--pine-light &,
  .page-action-color--pine-dark &,
  .page-action-color--earth-light &,
  .page-action-color--earth-dark &,
  .page-action-color--fir-light &,
  .page-action-color--fir-dark &,
  .page-action-color--lake-light &,
  .page-action-color--lake-dark & {
    // Remove space that appears in som browsers
    padding-top: 1px;
    margin-bottom: -1px;
  }

  .page-action-color--sea-light & {
    background-color: $color-sea-light;
  }
  .page-action-color--sea-dark & {
    background-color: $color-sea-dark;
  }
  .page-action-color--pine-light & {
    background-color: $color-pine-light;
  }
  .page-action-color--pine-dark & {
    background-color: $color-pine-dark;
  }
  .page-action-color--earth-light & {
    background-color: $color-earth-light;
  }
  .page-action-color--earth-dark & {
    background-color: $color-earth-dark;
  }
  .page-action-color--fir-light & {
    background-color: $color-fir-light;
  }
  .page-action-color--fir-dark & {
    background-color: $color-fir-dark;
  }
  .page-action-color--lake-light & {
    background-color: $color-lake-light;
  }
  .page-action-color--lake-dark & {
    background-color: $color-lake-dark;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    right: 0;
    height: 30%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.4) 35%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: 1;
  }

  @media screen and (orientation: landscape) {
    // Secondary menu height(top)
    min-height: calc(100vh - 40px);
  }
  @media screen and (orientation: portrait) {
    min-height: 50vh;
  }

  @include media-min(rem(1700px)) {
    @media screen and (orientation: landscape) {
      min-height: 80vh;
    }
  }
}

$polygon-horizontal-start-point: 64%;
.intro-bg {
  &,
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    clip-path: polygon(
      100% 60%,
      75% 77%,
      81% 84%,
      $polygon-horizontal-start-point 100%,
      0 100%,
      0% 0,
      100% 0
    );
  }
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.intro-image-active {
  // Add buffer to keep the animation going as the active slide changes
  animation-duration: $intro-animation-duration + 2000ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  &[data-direction='top-left'] {
    animation-name: intro-top-left;
  }
  &[data-direction='top-right'] {
    animation-name: intro-top-right;
  }
  &[data-direction='bottom-right'] {
    animation-name: intro-bottom-right;
  }
  &[data-direction='bottom-left'] {
    animation-name: intro-bottom-left;
  }
}

.intro-content-wrap {
  padding-top: rem($intro-content-padding);
  padding-bottom: rem($intro-content-padding);
  width: 100%;
  z-index: 2;
}
.intro-content {
  @include page-margin-reset;

  margin-left: 0;
  color: #fff;
  // Avoid text content displayed on polygon shape
  max-width: $polygon-horizontal-start-point + 5%;

  h1,
  h2 {
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
    line-height: 1;

    @include media-min(rem(1400px)) {
      font-size: rem(120px);
    }
  }
  p {
    text-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
  }
}
.intro-content-inner {
  margin-top: 30px;
}

.intro-content-image-container {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 2;
}

.intro-content-image-inner {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 100%;
  }
}

@include media-min(rem(700px)) {
  .intro {
    justify-content: flex-end;
  }
  .intro-content-image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  .intro-content-inner {
    margin-top: 0;
  }
}

// Sub menu with anchors
.in-page-nav {
  position: sticky;
  top: 0;
  margin-top: -1px;
  flex-wrap: wrap;
  padding: 10px 20px;
  color: #fff;
  font-size: rem(18px);
  font-family: $font-stack-alt;
  font-weight: $font-weight-bold;
  z-index: $z-in-page-nav;

  &:empty {
    display: none;
  }

  a {
    margin: 5px 15px;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
    @include media-min(rem(600px)) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
