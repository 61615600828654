/* - Newsroom side bar
---------------------------------------------------------------------------- */
.newsroom-sidebar {
  max-width: 360px;
  padding: 0px 34px;
  font-family: $font-stack-alt-second;
  font-weight: $font-weight-medium;
}

// Markets
.markets {
  display: flex;
}
.market {
  margin-bottom: 50px;
  margin-right: 10px;
  font-family: $font-stack-alt-second;
  font-weight: $font-weight-light;
  font-size: rem(18px);
  transition: scale 0.15s ease;
  a {
    padding: 5px 10px;
    background-color: #f1ece6;
    color: inherit;
    text-decoration: none;
    border-radius: 2px;

    &:hover,
    &:focus {
      background-color: darken(#f1ece6, 10%);
    }
  }
  .active {
    border-radius: 2px;
    padding: 5px 10px;
    background-color: $color-gray-dark;
    color: #fff;
  }
}

// Category tags
.category-tags {
  margin-bottom: 50px;
  font-size: rem(28px);
}

.category-tag {
  margin-bottom: 5px;
  line-height: 1.2;
  font-family: $font-stack-alt;

  a {
    color: inherit;
    text-decoration: none;
    color: rgba($color: #000000, $alpha: 0.5);
    text-decoration: none;

    &:hover,
    &:focus,
    &.active {
      color: $color-body-foreground;
    }
  }
  .active {
    text-decoration: none;
  }
}

// Latest articles
.latest-articles {
  margin-bottom: 50px;
}
.latest-article {
  padding: 5px;
  border-bottom: 1px solid #e0e0e0;
  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.article-publish-date {
  font-family: $font-stack-alt-second;
  font-weight: $font-weight-light;
  font-size: rem(16px);
  margin-right: 5px;
  flex-shrink: 0;
  margin-bottom: 0;
}

.latest-article-title {
  a {
    font-family: $font-stack-alt;
    font-weight: $font-weight-medium;
    color: inherit;
    font-size: rem(18px);
    text-decoration: none;
  }
  margin-bottom: 0;
}

// Media container with press images
.media-container {
  margin-bottom: 50px;
  a {
    text-transform: uppercase;
    text-decoration: none;
    font-family: $font-stack-alt;
    font-weight: $font-weight-bold;
    font-size: 18px;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  .icon--arrow {
    margin-left: 20px;
    vertical-align: baseline;
  }
}

.media-container-inner {
  @include grid-container(20px, 10px);

  .media-item-thumb {
    @include grid-item(1/4, 20px, 10px);
    @include grid-margin-bottom(20px, 10px);
  }
}

// Press contact info
.press-contact-text {
  font-family: $font-stack-main;
}

.press-contact-info {
  font-family: $font-stack-alt;
  font-weight: $font-weight-medium;
  font-size: rem(18px);

  .email-label,
  .phone-label {
    margin-right: 30px;
  }
  .email,
  .phone-number {
    text-decoration: underline;
    color: $color-red;
  }
}

@include media-max(rem(850px)) {
  .newsroom-sidebar {
    max-width: 100%;
    width: 100%;

    .markets {
      margin-bottom: 25px;
      .market {
        margin-bottom: 0;
      }
    }

    .category-tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 25px;

      .category-tag {
        margin-right: 10px;
      }
    }
  }
  .latest-articles {
    display: none;
  }

  .media-container,
  .press-contact-container {
    display: none;
  }

  @include media-max(rem(500px)) {
    .newsroom-sidebar {
      padding: 0 10px;
    }
  }
}
