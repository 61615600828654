/* -Contact persons block with contact person block
---------------------------------------------------------------------------- */
// Contact person

.contact-person {
  display: inline-flex;
  text-align: left;

  .contact-person-content {
    display: flex;
    flex-direction: column;
  }

  img {
    margin-right: 20px;
    width: 110px;
    height: 110px;
    border-radius: 26px;
  }

  h5 {
    margin-bottom: 0;
  }
  .role {
    text-transform: uppercase;
    font-size: rem(16px);
  }
  .label {
    margin-right: 5px;
    font-weight: $font-weight-bold;
    font-size: rem(11px);
  }
  a {
    color: $color-red;
    font-size: rem(16px);

    .callout-block &,
    .section-block--background & {
      color: inherit;
    }
  }
}

/*---------------------------------------------------------------------------- */

// Contact persons
.contact-persons-block {
  @include section-margin;

  text-align: center;

  .block-content & {
    @include page-margin-reset;

    text-align: left;
  }

  .block-content &,
  .callout-block-content & {
    @include section-margin(0.5);
  }
}

.contact-persons-block-text {
  margin-bottom: 60px;
}

.contact-persons-block-content {
  @include large-content-text;
}

.contact-persons-block-items {
  @include grid-container(45px, 20px);
  @include grid-pull-bottom(45px, 20px);
}

.contact-persons-block-item {
  @include grid-item(1, 45px, 20px);
  @include grid-margin-bottom(45px, 20px);

  @include media-max(rem(450px)) {
    .contact-person {
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}

.contact-persons-block--cols-2 {
  .contact-persons-block-item {
    @include grid-item-size(1/2);

    @include media-max(rem(800px)) {
      @include grid-item-size(1);

      .contact-person {
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.contact-persons-block--cols-3 {
  .contact-persons-block-item {
    @include grid-item-size(1/3);

    @include media-max(rem(800px)) {
      @include grid-item-size(1);

      .contact-person {
        flex-direction: column;
        align-items: center;
        text-align: center;
        img {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }

    @include media-between(rem(800px), rem(1200px)) {
      @include grid-item-size(1/2);
    }
  }
}
