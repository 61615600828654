/* -Icons
-----------------------------------------------------------------------------*/
/* AUTOGENERATED, DO NOT EDIT MANUALLY */

$icon-base-width: 16px;
$icon-base-height: 16px;
.icon {
  width: $icon-base-width;
  height: $icon-base-height;
  flex-grow: 0;
  flex-shrink: 0;
}

$icon-arrow-width: 24px;
$icon-arrow-height: 24px;
.icon--arrow {
  width: $icon-arrow-width;
  height: $icon-arrow-height;
}

$icon-chevron-width: 24px;
$icon-chevron-height: 24px;
.icon--chevron {
  width: $icon-chevron-width;
  height: $icon-chevron-height;
}

$icon-facebook-width: 16px;
$icon-facebook-height: 16px;
// Size matches base icon size, no rule generated

$icon-globe-width: 16px;
$icon-globe-height: 16px;
// Size matches base icon size, no rule generated

$icon-linkedin-width: 16px;
$icon-linkedin-height: 16px;
// Size matches base icon size, no rule generated

$icon-mail-width: 30px;
$icon-mail-height: 30px;
.icon--mail {
  width: $icon-mail-width;
  height: $icon-mail-height;
}

$icon-quote-sign-width: 80px;
$icon-quote-sign-height: 85px;
.icon--quote-sign {
  width: $icon-quote-sign-width;
  height: $icon-quote-sign-height;
}

$icon-search-width: 22px;
$icon-search-height: 22px;
.icon--search {
  width: $icon-search-width;
  height: $icon-search-height;
}

$icon-twitter-width: 24px;
$icon-twitter-height: 24px;
.icon--twitter {
  width: $icon-twitter-width;
  height: $icon-twitter-height;
}