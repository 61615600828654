/* -Blurb component - block of image, heading, short text and a link
-----------------------------------------------------------------------------*/
$blurb-column-gutter: 30px;
$blurb-column-gutter-small: 20px;

.blurb {
  position: relative;
  max-width: 510px !important;

  .overlay-link {
    // Don't let the overlay link cover the gutter
    @include grid-gutter-rule(
      'left',
      $blurb-column-gutter,
      $blurb-column-gutter-small
    );
  }
}
.blurb-inner {
  height: 100%;
  padding: 20px;
  display: flex;
  border-radius: 7px;
  background-color: #fff;
  color: $color-body-foreground;
  box-shadow: 0 5px 15px rgba(#000, 0.15);
  transition: transform 0.15s ease, box-shadow 0.15s ease;

  .blurb--has-link & {
    padding-right: 0;
  }

  .overlay-link:hover ~ &,
  .overlay-link:focus ~ & {
    transform: scale(1.01);
    box-shadow: 0 3px 6px rgba(#000, 0.15);
  }
}

.blurb-image {
  flex-shrink: 0;
  margin-right: 15px;
  margin-bottom: 10px;
  max-width: 50px;
}
.blurb-content {
  flex-grow: 1;
}
.blurb-title {
  margin-bottom: 0;
}
.blurb-text {
  margin-top: 5px;

  font-size: rem(18px);
  font-family: $font-stack-alt;
  font-weight: $font-weight-light;

  p {
    margin-bottom: 0;
  }
}
.blurb-link {
  align-self: center;

  .icon--chevron {
    width: 30px;
    height: 30px;
  }
}

@include media-min(rem(375px)) {
  .blurb-image {
    float: left;
    margin-bottom: 0;
  }
  .blurb-link {
    .icon--chevron {
      width: 40px;
      height: 40px;
    }
  }
}

@include media-min(rem(500px)) {
  .blurb-image {
    margin-right: 20px;
    max-width: 65px;
  }
  .blurb-text {
    font-size: rem(22px);
  }
  .blurb-link {
    .icon--chevron {
      width: 60px;
      height: 60px;
    }
  }
}

/*--------------- List of blurbs ---------------*/
.blurb-list-wrap {
  @include section-margin;

  &.blurb-list-wrap--cols-1 {
    @include wrap-custom(rem(480px));
  }
}

.blurb-list {
  @include grid-container($blurb-column-gutter, $blurb-column-gutter-small);

  .blurb {
    @include grid-item(1, $blurb-column-gutter, $blurb-column-gutter-small);
    @include grid-margin-bottom(
      $blurb-column-gutter,
      $blurb-column-gutter-small
    );
  }
}

@include media-min(rem(800px)) {
  .blurb-list-wrap--cols-2,
  .blurb-list-wrap--cols-3 {
    @include wrap-custom(rem(1020px));

    .blurb {
      @include grid-item(1/2, $blurb-column-gutter, $blurb-column-gutter-small);
    }
  }
}

@include media-min(rem(1200px)) {
  .blurb-list-wrap--cols-3 {
    @include wrap-width-wide;

    .blurb {
      @include grid-item(1/3, $blurb-column-gutter, $blurb-column-gutter-small);
    }
  }
}
