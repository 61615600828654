/* -'Image and content' block
-----------------------------------------------------------------------------*/
$image-content-columns-breakpoint: 850px;
$image-content-gutter: $grid-gutter;
$image-content-image-vertical-padding: 15px;

.image-content-block {
  @include section-margin;
  @include large-content-text;
}

.image-content-block-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-content-block-content {
  align-self: center;
}
.image-content-block-image {
  margin-top: 1.5em;
}

@include media-min(rem(500px)) {
  .image-content-block-image img {
    width: 100%;
    object-fit: cover;
  }
}

// Go to two columns
@include media-min(rem($image-content-columns-breakpoint)) {
  .image-content-block-inner {
    flex-direction: row;
    align-items: flex-start;
  }
  .image-content-block:nth-child(2n + 1) {
    .image-content-block-image {
      margin-left: $image-content-gutter;
    }
  }
  .image-content-block:nth-child(2n) {
    .image-content-block-inner {
      flex-direction: row-reverse;
    }
    .image-content-block-image {
      margin-right: $image-content-gutter;
    }
  }
  .image-content-block-image {
    flex-shrink: 0;
    width: auto;
    max-width: 38%;
    margin-top: 0;
    padding: $image-content-image-vertical-padding 0;
  }
}
