/* -Link with an arrow icon
---------------------------------------------------------------------------- */
.link-block-with-arrow {
  a {
    display: inline-block;
    padding: $arrow-link-padding 0;
    text-decoration: none;
    font-weight: $font-weight-bold;
    font-family: $font-stack-alt;
    color: inherit;
    text-transform: uppercase;
    font-size: rem(18px);
  }

  &:hover,
  &:focus {
    .text {
      text-decoration: underline;
    }
  }
  .icon--arrow {
    margin-left: 0.25em;
  }
}
