$wrap-width: rem(1060px);
$wrap-width-thin: rem(870px);
$wrap-width-wide: rem(1520px);
// Used for newsroom
$wrap-width-extra-thin: rem(740px);

$page-margin: rem(50px);
$page-margin-small: rem(20px);
$section-spacing: 80px;
$section-spacing-small: $section-spacing / 2;

$arrow-link-padding: 5px;

// Logo
$logo-width: 142px;
$logo-height: 67px;
$logo-width-small: round($logo-width * 0.8);
$logo-height-small: round($logo-height * 0.8);
$logo-width-tiny: round($logo-width * 0.65);
$logo-height-tiny: round($logo-height * 0.65);

// Form elements
// Using em instead of rem so that dimensions adapt to local font size changes
$form-control-base-height: 40px;
$form-control-font-size: 16px;
$form-control-radius: 4px;
$form-field-padding: em(5px, $form-control-font-size);
$form-control-height: em($form-control-base-height, $form-control-font-size);
$button-font-size: 22px;
$button-height: em($form-control-base-height, $button-font-size);

/*-------------------- Common breakpoints --------------------*/
// Most breakpoints should be isolated to what they affect and not be some
// arbitrary values, but global changes like text size may require adjustment
// to details in some instances and should therefore be a defined variable.

// Reduce text sizes
$breakpoint-type-medium: rem(1200px);
$breakpoint-type-small: rem(450px);

// Tighten layout
$breakpoint-grid-tight: rem(700px);
$breakpoint-page-margin-small: rem(550px);

$breakpoint-menu-toggle: rem(900px);
$breakpoint-logo-tiny: rem(400px);

$breakpoint-footer-nav-cols-2: rem(500px);
$breakpoint-footer-nav-cols-all: rem(850px);
