/* -Logotype list block with logos
---------------------------------------------------------------------------- */
$logotype-section-spacing: 50px;
$logotype-section-spacing-small: $logotype-section-spacing / 2;

.logotype-list-block {
  padding-top: $logotype-section-spacing;
  padding-bottom: $logotype-section-spacing;

  @include when-page-margin-small {
    padding-top: $logotype-section-spacing-small;
    padding-bottom: $logotype-section-spacing-small;
  }
}

.logotype-list-block-items {
  @include grid-container(60px, 20px);
  @include grid-pull-bottom(20px, 20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.logotype-list-block-item {
  @include grid-item(1/3, 60px, 20px);
  @include grid-margin-bottom(20px, 20px);

  text-align: center;

  .block-svg,
  .block-image {
    display: block;
  }

  img {
    max-width: 70px;
    max-height: 40px;
    @include media-min(rem(600px)) {
      max-width: 90px;
      max-height: 60px;
    }
  }
}

.logotype-list-block--cols-1 {
  .logotype-list-block-item {
    @include grid-item-size(1);
  }
}

.logotype-list-block--cols-2 {
  @include wrap-custom(rem(350px));
  .logotype-list-block-item {
    @include grid-item-size(1/2);
  }
}

.logotype-list-block--cols-3 {
  .logotype-list-block-item {
    @include grid-item-size(1/3);
  }
}

.logotype-list-block--cols-4 {
  .logotype-list-block-item {
    @include media-min(rem(400px)) {
      @include grid-item-size(1/4);
    }
  }
}

.logotype-list-block--cols-5 {
  .logotype-list-block-item {
    @include media-min(rem(400px)) {
      @include grid-item-size(1/4);
    }
    @include media-min(rem(500px)) {
      @include grid-item-size(1/5);
    }
  }
}

.logotype-list-block--cols-6 {
  @include media-min(rem(800px)) {
    @include wrap-width-wide;

    .logotype-list-block-item {
      @include grid-item-size(1/6);
    }
  }
}

.logotype-list-block--cols-7 {
  @include media-min(rem(400px)) {
    .logotype-list-block-item {
      @include grid-item-size(1/4);
    }
  }
  @include media-min(rem(950px)) {
    @include wrap-width-wide;

    .logotype-list-block-item {
      @include grid-item-size(1/7);
    }
  }
}

.logotype-list-block--cols-8 {
  @include media-min(rem(400px)) {
    .logotype-list-block-item {
      @include grid-item-size(1/4);
    }
  }

  @include media-min(rem(1000px)) {
    @include wrap-width-wide;
    .logotype-list-block-item {
      @include grid-item-size(1 / 8);
    }
  }
}
