/* - Paginator for newsroom
---------------------------------------------------------------------------- */
.paginator {
  ul {
    display: flex;
    list-style: none;
    margin: 0;
  }

  li {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    text-decoration: none;
    height: 40px;
    width: 40px;
    background-color: $color-light-beige;
    color: $color-red;
    font-family: $font-stack-alt;
    font-size: rem(18px);
    font-weight: $font-weight-bold;

    &:focus,
    &:hover {
      background-color: darken($color-light-beige, 10%);
    }
  }

  .paginator-current-item {
    a {
      border: 2px solid;
      background-color: #fff;
    }
  }

  .paginator-arrow {
    a {
      color: inherit;
      background-color: transparent;

      &:focus,
      &:hover {
        background-color: #f7f2ec;
      }
    }
  }

  .paginator-arrow-previous {
    a {
      transform: rotate(180deg);
    }
  }

  .paginator-arrow-next {
    margin-left: 20px;
  }

  .paginator-arrow--disabled {
    opacity: 0.3;
  }

  @include media-max(rem(600px)) {
    ul {
      flex-wrap: none;
    }
    .paginator-ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      margin-bottom: 5px;
    }
  }
}
