/* -Custom fonts
-----------------------------------------------------------------------------*/
@font-face {
  font-family: 'Creighton Pro';
  src: url('../fonts/creighton-pro-medium.woff') format('woff');
  font-weight: $font-weight-medium;
  font-style: normal;
}

@font-face {
  font-family: 'Creighton Pro';
  src: url('../fonts/creighton-pro-bold.woff') format('woff');
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: 'Creighton Pro';
  src: url('../fonts/creighton-pro-light.woff') format('woff');
  font-weight: $font-weight-light;
  font-style: normal;
}
@font-face {
  font-family: 'Creighton Pro Book';
  src: url('../fonts/creighton-pro-book.woff') format('woff');
  font-weight: $font-weight-light;
  font-style: normal;
}
