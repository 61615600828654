/* -Newsroom media item with files to download
---------------------------------------------------------------------------- */

.media-item-page-container {
  @include section-margin;

  .media-item-image {
    margin-bottom: 30px;
  }
  .media-item-image,
  .image.lazy {
    max-width: 1160px;
    max-height: 770px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.media-item-content {
  @include page-margin-reset;
}
.media-item-image--wrap-wide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-item-intro {
  @extend %lead-text;
}
.download-title {
  margin-top: 30px;
}
.download-card {
  position: relative;
  background-color: #f7f2ec;
  border-radius: 10px;
  box-shadow: 0px 5px 7px 0px rgba(193, 172, 147, 0.4);
  transition: transform 0.15s ease, box-shadow 0.15s ease;
  margin-bottom: 40px;
  padding: 20px;

  &:hover,
  &:focus {
    transform: scale(1.02);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
  }
  .icon--arrow {
    transform: rotate(45deg);
    height: 30px;
    width: 30px;
    flex-shrink: 0;
  }
  p {
    margin: 0;
  }
}

.download-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-card-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-all;

  h3 {
    margin-top: 5px;

    @include media-max($breakpoint-type-small) {
      font-size: rem($h3 - 4px);
    }
  }
}

.download-card-image {
  height: 85px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: 100%;
  }
}

.download-card-icon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

// Media queries
@include media-min(rem(400px)) {
  .download-card-content {
    align-items: flex-start;
  }
}

@include media-min(rem(550px)) {
  .media-item-page-container {
    @include section-margin;

    .media-item-image {
      margin-bottom: 60px;
    }
  }

  .download-title {
    margin-top: 60px;
  }
  .download-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .download-card-icon {
    margin-left: 10px;
    width: auto;
    .icon--arrow {
      height: 40px;
      width: 40px;
    }
  }
  .download-card-content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    p {
      font-size: rem(18px);
    }
  }
  .download-card-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-card-image {
    width: 120px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
  }
}
