/* -Organisation tree scheme
---------------------------------------------------------------------------- */
// Take particular note of all the pseudo elements and how they're stacked.
// The worst case is an item that has more than one level of children: there
// the dark vertical line extends beyond the final item, is covered/ereased by
// a light line that extends above the middle of the first child, which then
// has its gap covered/restored by a dark line.

$scheme-tree-line-width: 2px;
$scheme-tree-line-color: $color-dark;
$scheme-tree-cover-color: $color-body-background;
$scheme-item-border-width: 2px;
$scheme-level-indent: 40px;
$scheme-level-indent-large: 80px;
$scheme-item-margin: 10px;
$scheme-item-margin-large: 20px;

.organisation-scheme {
  @include section-margin;

  ul {
    list-style: none;
    margin: 0;
  }
  ul ul {
    // Clear large screen float
    overflow: hidden;
    padding-left: $scheme-level-indent;
  }
}

.organisation-scheme__node {
  position: relative;
  margin-top: $scheme-item-margin;

  .organisation-scheme > ul > &:first-child {
    margin-top: 0;
  }
}
.organisation-scheme__node--has-children {
  // Prevent box-shadow being clipped by moving spacing from li top margin
  // to ul bottom padding where relevant.
  & + li {
    margin-top: 0;
  }
  > ul {
    position: relative;
    padding-bottom: $scheme-item-margin;

    // Vertical line in front of nested items
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -$scheme-item-margin;
      bottom: $scheme-item-margin;
      left: $scheme-level-indent / 2;
      width: $scheme-tree-line-width;
      background: $scheme-tree-line-color;
    }
  }

  // Cover the part of the vertical line that extends below the middle of the
  // final item.
  &:last-child::after,
  > ul > li:last-child::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: -($scheme-level-indent / 2);
    margin-top: $scheme-tree-line-width;
    width: $scheme-tree-line-width;
    background: $scheme-tree-cover-color;
  }
  &:last-child::after {
    top: 0 !important;
  }
}
.js {
  .organisation-scheme__node--has-toggle {
    > ul {
      // Using display none will remove spacing between nodes.
      visibility: hidden;
      height: $scheme-item-margin;
    }
  }
  .organisation-scheme__node--children-visible {
    > ul {
      visibility: visible;
      height: auto !important;
    }
  }
  .organisation-scheme__item-wrap {
    display: inline-flex;
  }
}

.organisation-scheme__item {
  position: relative;
  display: inline-block;
  min-width: 100px;
  padding: 10px 15px;
  border: $scheme-item-border-width solid transparent;
  border-radius: 10px;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.15s ease, border-color 0.15s ease;

  // Horizontal line to each item and vertical line filling in what's covered
  // by the has-children line above.
  ul ul &::before,
  ul ul &::after {
    content: '';
    display: block;
    position: absolute;
    background: $scheme-tree-line-color;
    // Item can be a link, ignore clicks on the line
    pointer-events: none;
  }
  ul ul &::before {
    top: 50%;
    right: 100%;
    margin-right: $scheme-item-border-width;
    width: $scheme-level-indent / 2;
    height: $scheme-tree-line-width;
    z-index: 3;
  }
  ul ul &::after {
    top: 0;
    bottom: 50%;
    margin-bottom: -$scheme-item-border-width;
    left: -($scheme-level-indent / 2 + $scheme-item-border-width);
    width: $scheme-item-border-width;
    z-index: 2;
  }
  .icon--chevron {
    position: relative;
    left: 15px;
    width: 40px;
    height: 40px;
    margin-top: -10px;
    margin-bottom: -10px;
    margin-left: auto;
  }
}
a.organisation-scheme__item {
  &:hover,
  &:focus {
    .organisation-scheme__sub-title {
      text-decoration: underline;
    }
  }
}
a.organisation-scheme__item--no-text {
  display: inline-flex;
  align-items: center;
  text-align: left;
  min-width: 150px;
}

.organisation-scheme__item--black {
  border-color: $color-dark;
  background-color: $color-dark;
  color: #fff !important;

  @at-root a#{&} {
    &:hover,
    &:focus {
      border-color: lighten($color-dark, 15%);
      background-color: lighten($color-dark, 15%);
    }
  }
}
.organisation-scheme__item--gray {
  border-color: #6b6b6b;
  background-color: #6b6b6b;
  color: #fff !important;

  @at-root a#{&} {
    &:hover,
    &:focus {
      border-color: darken(#6b6b6b, 10%);
      background-color: darken(#6b6b6b, 10%);
    }
  }
}
.organisation-scheme__item--red {
  border-color: $color-red;
  background-color: $color-red;
  color: #fff !important;

  @at-root a#{&} {
    &:hover,
    &:focus {
      border-color: darken($color-red, 10%);
      background-color: darken($color-red, 10%);
    }
  }
}
.organisation-scheme__item--beige {
  border-color: $color-beige;
  background-color: $color-beige;

  @at-root a#{&} {
    &:hover,
    &:focus {
      border-color: darken($color-beige, 10%);
      background-color: darken($color-beige, 10%);
    }
  }
}
.organisation-scheme__item--white {
  border-color: $color-dark;
  background-color: #fff;

  @at-root a#{&} {
    &:hover,
    &:focus {
      background-color: darken(#fff, 10%);
    }
  }
}

.organisation-scheme__children-toggle {
  // Will get stretched via flexbox.
  height: auto;
  padding: 0 10px;
  background: transparent !important;
  color: inherit;
  font-size: 28px;

  &:hover,
  &:focus {
    color: $color-main;
  }
}

.organisation-scheme__name {
  // The alternative font doesn't have a vertically centered baseline.
  margin-bottom: -2px;
  font-family: $font-stack-alt;
  font-size: rem(22px);
  line-height: 1.1;
  font-weight: $font-weight-medium;
}
.organisation-scheme__sub-title {
  margin-top: 3px;
  font-size: rem(13px);
}

@include media-min(rem(600px)) {
  .organisation-scheme {
    ul ul {
      padding-left: $scheme-level-indent-large;
    }
  }
  .organisation-scheme__node {
    margin-top: $scheme-item-margin-large;
  }
  .organisation-scheme__node--has-children {
    > ul {
      padding-bottom: $scheme-item-margin-large;

      &::before {
        top: -$scheme-item-margin-large;
        bottom: $scheme-item-margin-large;
        left: $scheme-level-indent-large / 2;
      }
    }
    &:last-child::after,
    > ul > li:last-child::after {
      left: -($scheme-level-indent-large / 2);
    }
  }
  .js .organisation-scheme__node--has-toggle > ul {
    height: $scheme-item-margin-large;
  }
  .organisation-scheme__children-toggle {
    padding-right: 20px;
  }

  .organisation-scheme__item {
    ul ul &::before {
      width: $scheme-level-indent-large / 2;
    }
    ul ul &::after {
      left: -($scheme-level-indent-large / 2 + $scheme-item-border-width);
    }
  }

  .organisation-scheme__item {
    min-width: 150px;
    padding: 15px 20px;
  }
  a.organisation-scheme__item--no-text {
    min-width: 200px;
  }
  .organisation-scheme__name {
    font-size: rem(28px);
  }
  .organisation-scheme__sub-title {
    font-size: rem(14px);
  }
}

@include media-min(rem(850px)) {
  // Make the first level horizontal
  .organisation-scheme {
    > ul {
      // Extend the first sub item's line to make it reach the top level item
      // that's now to the left.
      > li
        > ul
        > .organisation-scheme__node:first-child
        > .organisation-scheme__item-wrap
        .organisation-scheme__item::before {
        width: $scheme-level-indent-large;
      }
      > .organisation-scheme__node--has-children {
        > .organisation-scheme__item-wrap {
          float: left;
        }
        > .organisation-scheme__item-wrap ~ ul {
          &::before {
            // Move the top part of the vertical line down a bit to ensure
            // it's covered by the line added below.
            top: $scheme-item-border-width;
          }
          > li:first-child {
            margin-top: 0;

            // Cover the top part of the vertical line that doesn't attach to
            // the top most item anymore, since it's now to the left.
            > .organisation-scheme__item-wrap
              .organisation-scheme__item::after {
              content: '';
              display: block;
              position: absolute;
              top: -$scheme-item-border-width;
              left: -($scheme-level-indent-large / 2) - $scheme-item-border-width;
              bottom: 50%;
              width: $scheme-tree-line-width;
              background: $scheme-tree-cover-color;
            }
          }
        }
      }
    }
  }
}
