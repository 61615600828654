/* -'Images' block
-----------------------------------------------------------------------------*/
.images-block-item {
  > a {
    display: block;
  }
}

.images-block-item-caption {
  .images-block--cols-1 &,
  .images-block--cols-2 &,
  .images-block--gallery & {
    margin-top: 5px;
    font-size: rem(14px);
  }
}

.images-block-item-image {
  a {
    transition: opacity 0.15s ease;

    &:hover,
    &:focus {
      opacity: 0.75;
    }
  }
}

/*-------------------- Columns layout --------------------*/
$images-block-columns-gutter: 40px;
.images-block--cols-2 {
  column-count: 2;
  column-gap: $images-block-columns-gutter;
  @include grid-margin-bottom(
    $images-block-columns-gutter,
    $images-block-columns-gutter / 2
  );

  @include when-grid-tight {
    column-gap: $images-block-columns-gutter / 2;
  }
  @include media-max(400px) {
    column-count: 1;
  }
}
.images-block--cols-1,
.images-block--cols-2 {
  .images-block-item {
    @include grid-margin-bottom(
      $images-block-columns-gutter,
      $images-block-columns-gutter / 2
    );

    // Create a new block formatting context to prevent potential spilling
    // from one column to another
    overflow: hidden;
  }
}

/*-------------------- Gallery layout --------------------*/
$images-block-gallery-gutter: 30px;
.images-block--gallery {
  @include grid-container(
    $images-block-gallery-gutter,
    $images-block-gallery-gutter / 2
  );
  @include grid-pull-bottom(
    $images-block-gallery-gutter,
    $images-block-gallery-gutter / 2
  );

  .images-block-item {
    @include grid-item(
      1 / 4,
      $images-block-gallery-gutter,
      $images-block-gallery-gutter / 2
    );
    @include grid-margin-bottom(
      $images-block-gallery-gutter,
      $images-block-gallery-gutter / 2
    );
  }
  .images-block-item:first-child,
  .images-block-item:first-child + .images-block-item {
    @include grid-item-size(1 / 2);
  }
}

/*-------------------- Text-to-the-side layout --------------------*/
.images-block--side-text {
  @include grid-margin-top;

  .images-block-item {
    @include grid-container;
    @include grid-margin-bottom(40px, 20px);

    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .images-block-item-image,
  .images-block-item-caption {
    @include grid-item-gutter;
  }
  .images-block-item-image {
    flex-shrink: 0;
    max-width: 50%;
  }

  @include media-max(rem(550px)) {
    .images-block-item {
      @include grid-container-reset;

      display: block;
      margin-bottom: 50px;
    }
    .images-block-item-image,
    .images-block-item-caption {
      @include grid-item-reset;
    }
    .images-block-item-image {
      max-width: none;
    }
    .images-block-item-caption {
      margin-top: 10px;
    }
  }
}
