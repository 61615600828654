/* -General table styles
---------------------------------------------------------------------------- */
.table-wrap {
  overflow-x: auto;
  margin-bottom: $text-spacing;

  table {
    margin-bottom: 0;
  }
}

table {
  border-collapse: collapse;
  border-top: 1px solid $color-table-border;
  border-bottom: 1px solid $color-table-border;
}

th,
td {
  padding: 15px 10px;
  border-top: 1px solid $color-table-border;
  border-bottom: 1px solid $color-table-border;
  font-size: rem(14px);
  background: #f2f2f2;
  width: 175px;

  .section-block--beige &,
  .section-block--dark & {
    background: #fff;
    color: $color-body-foreground;
  }
}

th {
  text-align: left;
}
