/* - Newsroom sub header
---------------------------------------------------------------------------- */
$search-field-height: 40px;

.sub-header {
  background-color: $color-header-background;
  display: flex;
  justify-content: center;
  padding: 30px;
  border-top: 1px solid #292929;

  // if search
  form {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    border-radius: 20px;
    background: #fff;
    height: $search-field-height;
  }

  input[type='text'] {
    padding-left: 20px;
    max-width: 600px;
    height: $search-field-height;
    border-radius: 20px;
    flex-grow: 2;
    border-color: #fff;
    font-family: $font-stack-alt-second;
    font-size: rem(18px);
  }

  button[type='submit'] {
    padding: 0;
    width: 60px;
    height: $search-field-height;
    color: inherit;
    background: transparent;
    .show-focus &:focus {
      outline: 0;
      border: 2px solid $color-blue;
      border-radius: $search-field-height / 2;
    }
  }

  .sub-header-media-item {
    color: #fff;
    text-transform: uppercase;
    .icon--arrow {
      transform: rotate(180deg);
      margin-right: 20px;
      vertical-align: baseline;
    }
    a {
      color: inherit;
      text-decoration: none;
      font-family: $font-stack-alt;
      font-size: rem(18px);
      font-weight: $font-weight-bold;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
