/* -Intro image for key person page
---------------------------------------------------------------------------- */
.key-person-page-container {
  .intro {
    max-height: 700px;

    @media screen and (orientation: landscape) {
      min-height: 60vh;
    }
    @media screen and (orientation: portrait) {
      min-height: 60vh;
    }
  }
}
