/* -Section block
---------------------------------------------------------------------------- */
.section-block {
  @include section-padding;

  > .block-content:first-child {
    @include section-margin-bottom;
  }
  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }
}

.section-block--background {
  background-position: 50% 0;
  background-size: cover;
  background-repeat: no-repeat;

  .btn,
  a.btn,
  button {
    @include button-secondary;
  }

  &.section-block--sea-dark,
  &.section-block--pine-dark,
  &.section-block--earth-dark,
  &.section-block--fir-dark,
  &.section-block--lake-dark {
    color: #fff;
  }
}

@each $color
  in (
    'white',
    'earth-light',
    'earth-dark',
    'sea-light',
    'sea-dark',
    'lake-light',
    'lake-dark',
    'pine-light',
    'pine-dark',
    'fir-light',
    'fir-dark'
  )
{
  .section-block--#{$color} + .section-block--#{$color} {
    @include section-pull-top;
  }
}
