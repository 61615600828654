/* -The global footer area
-----------------------------------------------------------------------------*/
.site-footer {
  width: 100%;
}
.footer-navigation {
  padding-top: 40px;
  padding-bottom: 50px;
}

.footer-nav-groups {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  h4 {
    font-size: rem(24px);
  }

  ul {
    list-style: none;
    font-size: rem(18px);
    font-family: $font-stack-alt-second;
    font-weight: $font-weight-light;
    margin: 0;
    a {
      color: inherit;
      text-decoration: none;
      font-family: inherit;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-nav-group {
  &:not(:last-child) {
    padding-right: 0;
  }
  margin-bottom: 40px;
}
.site-footer-logo {
  max-width: 180px;
}

@include media-min($breakpoint-footer-nav-cols-2) {
  .footer-navigation {
    display: flex;
    justify-content: center;
  }
  .footer-nav-groups {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .footer-nav-group {
    &:not(:last-child) {
      padding-right: 40px;
    }
    &:nth-child(even) {
      width: 65%;
    }
    &:nth-child(odd) {
      width: 35%;
    }

    &.footer-nav-group-logo {
      width: 100%;
    }
  }
  .site-footer-logo {
    max-width: 220px;
  }
}
@include media-min($breakpoint-footer-nav-cols-all) {
  .footer-nav-groups {
    flex-wrap: nowrap;
    margin-bottom: 0;
  }

  .footer-nav-group {
    width: auto !important;
    flex: 1 1 auto;
    margin-bottom: 0;

    &:not(:last-child) {
      padding-right: 80px;
    }
  }
}

@include media-min(rem(1500px)) {
  .site-footer-logo {
    max-width: 285px;
  }
}

.footer-contact {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: $color-footer-bottom;
  font-size: rem(18px);
  font-family: $font-stack-alt;
  font-weight: $font-weight-bold;
  padding: 20px 20px;

  p {
    margin: 0;
    &:not(:first-child) {
      margin-left: 30px;
      a {
        color: inherit;
      }
    }
  }

  .footer-contact-item {
    font-family: $font-stack-alt-second;
    font-weight: $font-weight-light;
    margin-left: 5px;
  }
}
