/* -Highlighted news with image, title, news meta and a link
-----------------------------------------------------------------------------*/
$higlighted-news-column-gutter: 40px;
$higlighted-news-column-gutter-small: 40px;

.highlighted-news-container {
  @include section-margin;
}
.highlighted-news-content {
  @include page-margin-reset;
  margin-bottom: 30px;
  margin-top: 0;
}

.highlighted-news-articles {
  @include grid-container(
    $higlighted-news-column-gutter,
    $higlighted-news-column-gutter-small
  );
  @include grid-pull-bottom(
    $higlighted-news-column-gutter,
    $higlighted-news-column-gutter-small
  );
}

.highlighted-news-article {
  @include grid-item(
    1,
    $higlighted-news-column-gutter,
    $higlighted-news-column-gutter-small
  );
  @include grid-margin-bottom(
    $higlighted-news-column-gutter,
    $higlighted-news-column-gutter-small
  );
}
.highlighted-news-article-image {
  margin-bottom: 10px;

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}
.highlighted-news-article-meta {
  font-size: rem(18px);

  .publish-time {
    padding-right: 15px;
    border-right: 1px solid currentColor;
  }
  .publish-date {
    margin-left: 15px;
  }
}

@include media-min(rem(650px)) {
  .highlighted-news-article {
    @include grid-item-size(1/2);
  }
}

@include media-min(rem(1000px)) {
  .highlighted-news-article {
    @include grid-item-size(1/3);
  }
}

@include media-min(rem(1200px)) {
  .highlighted-news-article {
    @include grid-item-size(1/4);
  }
}
