/* -The main header area with logo and navigation
-----------------------------------------------------------------------------*/
.site-header {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: $font-stack-alt;
  z-index: 1000;

  .current-item,
  a:hover,
  a:focus {
    text-decoration: underline;
  }
  a {
    text-decoration: none;
  }
}
.site-header-top {
  padding: 12px 10px 10px 10px;
  min-height: 40px;
  font-weight: $font-weight-regular;
  font-family: $font-stack-alt-second;
  line-height: 1;
  text-transform: uppercase;

  li {
    font-size: rem(18px);
  }
}
.header-lang-item {
  display: flex;
  justify-content: flex-end;
  font-size: rem(16px);

  .icon--globe {
    margin-right: 5px;
    margin-bottom: 2px;
  }
}
.site-header-main {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 5px;
  font-size: rem(22px);
  font-weight: $font-weight-medium;
  background-color: $color-gray-light;

  .has-intro & {
    position: absolute;
    top: 40px; // Top menu height
    left: 0;
    right: 0;
    background-color: rgba(255, 555, 255, 0);
    color: #fff;
  }
}
.site-header-logo {
  margin-right: 10px;
}
.site-header-action {
  margin-left: 10px;
  text-align: right;

  a.btn {
    white-space: nowrap;

    .page-action-color--sea-light & {
      @include button-sea-light;
    }
    .page-action-color--sea-dark & {
      @include button-sea-dark;
    }
    .page-action-color--pine-light & {
      @include button-pine-light;
    }
    .page-action-color--pine-dark & {
      @include button-pine-dark;
    }
    .page-action-color--earth-light & {
      @include button-earth-light;
    }
    .page-action-color--earth-dark & {
      @include button-earth-dark;
    }
    .page-action-color--fir-light & {
      @include button-fir-light;
    }
    .page-action-color--fir-dark & {
      @include button-fir-dark;
    }
    .page-action-color--lake-light & {
      @include button-lake-light;
    }
    .page-action-color--lake-dark & {
      @include button-lake-dark;
    }
  }
}

// Nav menu
.site-header-nav {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
  }
  li {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

/* ------------------- Larger screens ------------------- */

@include media-min($breakpoint-menu-toggle) {
  .site-header-top {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 0;

    nav {
      grid-column-start: 2;
    }
    li {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }
    .header-lang-item {
      grid-column-start: 3;
    }
  }
  .site-header-main {
    padding: 15px 30px;
  }
  .site-header-logo,
  .site-header-action {
    min-width: 142px;
  }
  .site-header-logo {
    flex: 1;
  }
  .site-header-action {
    flex: 1;
  }
  .site-header-nav {
    .menu-item--secondary {
      display: none !important;
    }
  }
}

@include media-min(rem(1200px)) {
  // Nav menu
  .site-header-nav {
    li {
      &:not(:last-child) {
        margin-right: 50px;
      }
    }
  }
}

/* ------------------- Small screen menu toggle ------------------- */
$header-small-height: 65px;
$header-small-medium-height: 74px;
// Header is no longer aboslute positioned when no intro
// and need to be included in header height.
$header-small-height-no-intro: 105px;
$header-small-medium-height-no-intro: 114px;

.menu-toggle {
  display: none !important;
  position: relative;
  padding-right: 35px;
  background: transparent !important;
  color: $color-body-foreground;
  border: 1px solid currentColor;

  .has-intro & {
    color: #fff;
  }

  &:hover,
  &:focus {
    border-color: currentColor;
  }
}
.menu-toggle-icon {
  top: 50%;
  right: 15px;

  &,
  &::before,
  &::after {
    position: absolute;
    width: 13px;
    height: 2px;
    background-color: currentColor;
    transition: background-color 0.15s ease;
  }
  &::before,
  &::after {
    content: '';
    display: block;
    left: 0;
    transition: transform 0.15s ease;
  }
  &::before {
    top: 0;
    transform: translateY(-5px);
  }
  &::after {
    bottom: 0;
    transform: translateY(5px);
  }
  .nav-open & {
    background-color: rgba(255, 255, 255, 0);

    &::before {
      transform: translateY(0) rotate(-225deg);
    }
    &::after {
      transform: translateY(0) rotate(225deg);
    }
  }
}

@include media-max($breakpoint-menu-toggle) {
  .site-header-top {
    .header-nav--secondary {
      display: none;
    }
    .site-header--no-lang & {
      padding: 0;
      min-height: 0;
    }
  }
  .site-header-main {
    .site-header--no-lang & {
      top: 0;
    }
  }
  .site-header-nav {
    flex-grow: 1;
    text-align: right;
  }
  .site-header-action {
    z-index: 10;
  }
  .site-header-action:empty {
    display: none;
  }
  .menu-toggle {
    display: inline-block !important;
    display: inline-flex !important;

    .nav-open & {
      color: gray;
      border: 1px solid;
      z-index: 10;
      transition: color 0.15s ease; // Button text fades in
    }
  }

  $toggled-menu-transition: 0.2s;

  // The pseudo elements are the black transparent and white opaque backgrounds
  .site-header-nav::before,
  .site-header-nav::after {
    content: '';
    display: block;
    position: absolute;
    height: $header-small-height-no-intro;
    top: 0;
    right: 0;

    .has-intro &,
    .site-header--no-lang & {
      height: $header-small-height;
    }
  }

  .main-nav,
  .site-header-nav::before {
    visibility: hidden;
    width: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color $toggled-menu-transition ease,
      visibility 0s ease $toggled-menu-transition,
      width 0s ease $toggled-menu-transition;

    .nav-open & {
      visibility: visible;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition-delay: 0s;
    }
  }

  .main-nav > ul,
  .site-header-nav::after {
    width: 0;
    max-width: 350px;
    background: #fff;
    transform: translateX(100%);
    transition: transform $toggled-menu-transition ease;

    .nav-open & {
      transform: translateX(0);
      width: 100%;
    }
  }

  .main-nav {
    position: absolute;
    top: 100%;
    left: 0;
    height: calc(100vh - #{$header-small-height});
    overflow-x: hidden;
    overflow-y: auto;
    text-align: right;

    ul {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      display: inline-block;
      min-height: 100%;
    }
    li {
      position: relative;
      display: block;
      text-align: left;
      text-transform: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0 !important;
      padding: 0;

      &.active > a::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 3px;
        width: 7px;
        height: 2px;
        margin-top: -1px;
      }
      a {
        padding: 7px 20px;
        color: $color-body-foreground !important;
      }
    }
  }

  .nav-open,
  .nav-open body {
    overflow: hidden;
  }
  .nav-open {
    .intro-content {
      z-index: 1;
    }
  }
}

@include media-min($breakpoint-logo-tiny) {
  .site-header-nav::before,
  .site-header-nav::after {
    height: $header-small-medium-height-no-intro;

    .has-intro &,
    .site-header--no-lang & {
      height: $header-small-medium-height;
    }
  }
}
