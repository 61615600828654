/* -Buttons
-----------------------------------------------------------------------------*/

/*---------- Mixins ----------*/
@mixin button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $button-height;
  padding: 4px 15px 0 15px;
  border: 0;
  border-radius: $form-control-radius;
  font-weight: $font-weight-regular;
  font-size: rem($button-font-size);
  font-family: $font-stack-alt;
  line-height: $button-height - em(2px, $button-font-size);
  text-decoration: none;
  text-align: center;
  text-shadow: none;
  vertical-align: middle;
  box-shadow: none;
  transition: background-color 0.15s ease-out;

  &:focus {
    outline: 1px dotted $color-body-foreground;
  }
}

@mixin button-primary-base-colors {
  background-color: $color-gray-dark;
  color: #fff;
}
@mixin button-primary-colors {
  @include button-primary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: lighten($color-gray-dark, 15%);
    }
  }
}
@mixin button-primary {
  @include button;
  @include button-primary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

@mixin button-secondary-base-colors {
  background-color: #fff;
  color: $color-body-foreground;
}
@mixin button-secondary-colors {
  @include button-secondary-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken(#fff, 10%);
    }
  }
}
@mixin button-secondary {
  @include button;
  @include button-secondary-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Sea light theme
@mixin button-sea-light-base-colors {
  background-color: $color-sea-light;
  color: $color-body-foreground;
}
@mixin button-sea-light-colors {
  @include button-sea-light-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-sea-light, 15%);
    }
  }
}
@mixin button-sea-light {
  @include button;
  @include button-sea-light-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Sea dark theme
@mixin button-sea-dark-base-colors {
  background-color: $color-sea-dark;
  color: #fff;
}
@mixin button-sea-dark-colors {
  @include button-sea-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-sea-dark, 5%);
    }
  }
}
@mixin button-sea-dark {
  @include button;
  @include button-sea-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Earth light theme
@mixin button-earth-light-base-colors {
  background-color: $color-earth-light;
  color: $color-body-foreground;
}
@mixin button-earth-light-colors {
  @include button-earth-light-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-earth-light, 15%);
    }
  }
}
@mixin button-earth-light {
  @include button;
  @include button-earth-light-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Earth dark theme
@mixin button-earth-dark-base-colors {
  background-color: $color-earth-dark;
  color: #fff;
}
@mixin button-earth-dark-colors {
  @include button-earth-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-earth-dark, 2%);
    }
  }
}
@mixin button-earth-dark {
  @include button;
  @include button-earth-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Pine light theme
@mixin button-pine-light-base-colors {
  background-color: $color-pine-light;
  color: $color-body-foreground;
}
@mixin button-pine-light-colors {
  @include button-pine-light-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-pine-light, 15%);
    }
  }
}
@mixin button-pine-light {
  @include button;
  @include button-pine-light-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Pine dark theme
@mixin button-pine-dark-base-colors {
  background-color: $color-pine-dark;
  color: #fff;
}
@mixin button-pine-dark-colors {
  @include button-pine-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-pine-dark, 15%);
    }
  }
}
@mixin button-pine-dark {
  @include button;
  @include button-pine-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Fir light theme
@mixin button-fir-light-base-colors {
  background-color: $color-fir-light;
  color: $color-body-foreground;
}
@mixin button-fir-light-colors {
  @include button-fir-light-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-fir-light, 15%);
    }
  }
}
@mixin button-fir-light {
  @include button;
  @include button-fir-light-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Fir dark theme
@mixin button-fir-dark-base-colors {
  background-color: $color-fir-dark;
  color: #fff;
}
@mixin button-fir-dark-colors {
  @include button-fir-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-fir-dark, 5%);
    }
  }
}
@mixin button-fir-dark {
  @include button;
  @include button-fir-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Lake light theme
@mixin button-lake-light-base-colors {
  background-color: $color-lake-light;
  color: $color-body-foreground;
}
@mixin button-lake-light-colors {
  @include button-lake-light-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-lake-light, 15%);
    }
  }
}
@mixin button-lake-light {
  @include button;
  @include button-lake-light-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

// Lake dark theme
@mixin button-lake-dark-base-colors {
  background-color: $color-lake-dark;
  color: #fff;
}
@mixin button-lake-dark-colors {
  @include button-lake-dark-base-colors;

  &:not([disabled]) {
    &:hover,
    &:focus {
      background-color: darken($color-lake-dark, 5%);
    }
  }
}
@mixin button-lake-dark {
  @include button;
  @include button-lake-dark-colors;

  &[disabled] {
    opacity: 0.5;
  }
}

/*---------- Standard button classes ----------*/
.btn,
a.btn,
button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
  @include button-primary;

  &[disabled] {
    cursor: default;
  }
  > span {
    vertical-align: middle;
  }
  .icon,
  .spinner {
    &:first-child {
      margin-right: 0.5em;
    }
    &:last-child {
      margin-left: 0.5em;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
      vertical-align: top;
    }
  }
}

a.btn-secondary,
.btn-secondary {
  @include button-secondary;
}

// ----- Special use case buttons -----

// Semantically a button but doesn't have any styles to indicate it
@mixin btn-neutral {
  height: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent !important;
  color: inherit !important;
}
// .btn-neutral {
//   @include btn-neutral;
//
//   .no-js & {
//     cursor: default;
//   }
// }
