/* - Newsroom
---------------------------------------------------------------------------- */

//News room page
.newsroom-page-container {
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;

  h1 {
    font-size: rem(40px);

    @include media-min($breakpoint-type-medium) {
      font-size: rem(48px) !important;
    }
  }
}

.news-list-article {
  .news-list-article-content {
    margin-bottom: 80px;

    h2 {
      @extend %newsroom_h2;
    }

    .time,
    .date,
    .tag {
      font-size: rem(18px);
      font-family: $font-stack-main;
    }
    .time {
      padding-right: 20px;
      border-right: 1px solid;
    }
    .date {
      padding: 0 20px;
    }
    .tag {
      &:first-child {
        padding-left: 20px;
        border-left: 1px solid $color-body-foreground;
      }
      margin-right: 10px;
      color: $color-red;
      text-decoration: underline;
    }
  }

  &.news-list-article--first-article {
    .news-list-article-content-text {
      margin-top: 15px;
    }
    .news-list-article-image {
      text-align: center;
      max-width: 750px;
      background-color: $color-gray-light;
      .image {
        max-width: 750px;
      }
      img {
        max-height: 500px;
      }
    }
    .news-list-article-intro-text {
      p,
      span {
        @include large-content-text;

        font-family: $font-stack-main;
        font-weight: normal;
      }
    }
  }

  &:not(.news-list-article--first-article) {
    .news-list-article-content {
      display: flex;
      align-items: flex-start;
    }
    .news-list-article-image {
      width: 250px;
      margin-right: 40px;
      text-align: center;
      background-color: $color-gray-light;

      .image {
        width: 250px;
      }

      img {
        max-height: 200px;
      }
    }
    .news-list-article-intro-text {
      @include large-content-text;

      display: block;
      p {
        margin: 0;
      }
    }
    .news-list-article-intro-text--has-image {
      display: none;
    }
  }
}
.newsroom-sidebar-small {
  display: none;
}

@include media-max(rem(850px)) {
  .newsroom-page-container {
    flex-direction: column;
  }

  .newsroom-list-articles-wrapper {
    margin-left: 0;
    margin-right: 0;
  }

  .news-list-article {
    .news-list-article-content {
      margin-bottom: 40px;
    }
    &.news-list-article--first-article {
      .news-list-article-content-text {
        margin-top: 15px;
      }
    }

    &:not(.news-list-article--first-article) {
      .news-list-article-content {
        display: flex;
        flex-direction: column;
      }
      .news-list-article-content-text {
        margin-top: 10px;
      }
    }
  }
  .newsroom-sidebar-small {
    display: block;
    margin-top: 40px;
    padding: 0;

    &.newsarticle-sidebar-small {
      margin-top: 0;
    }
    .media-container {
      display: block;
      margin-bottom: 25px;
      .media-container-inner {
        @include grid-container(10px, 10px);

        .media-item-thumb {
          @include grid-item(1/6, 10px, 10px);
          @include grid-margin-bottom(10px, 10px);

          @include media-max(rem(500px)) {
            @include grid-item(1/3, 10px, 10px);
          }
        }
      }
    }
    .press-contact-container {
      display: block;
      margin-bottom: 25px;
    }
  }
}
