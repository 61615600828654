/* -Global bits and pieces
-----------------------------------------------------------------------------*/

html {
  scroll-behavior: smooth;
}

.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

// Effectively disable all animations if the user doesn't want them
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.001s !important;
    transition-duration: 0.001s !important;
  }
  html {
    scroll-behavior: auto;
  }
}

// Class handled with JavaScript, which tries to take accessibility in mind.
// Sets hide-focus when a mouse is used and show focus when a keyboard is.
// Hide outlines for mouse users and make it more distinct for keyboard users.
a,
button,
input[type='submit'],
input[type='button'],
input[type='reset'],
[tabindex] {
  .hide-focus & {
    outline: 0 !important;
  }
  .show-focus &:focus {
    outline: 3px dotted $color-body-foreground;
    outline-offset: 1px;
  }
  // Override outline colors when necessary
  .show-focus .lightbox &:focus {
    outline-color: #fff;
  }
}

.error-page-content {
  margin: 80px 20px 0;
  min-height: 80vh;

  @include media-max($breakpoint-grid-tight) {
    margin: 40px 0;
  }
}
