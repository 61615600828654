/* -Basic content block
-----------------------------------------------------------------------------*/
// The content block is just a content stream. A content stream can also be
// part of other blocks, so the block template can't have a wrapper using one
// of the wrapping classes.
// All blocks are wrapped in a block-[name] container, so use that for the
// block itself rather than its content.
.block-content {
  @include wrap-wide;
  @include large-content-text;
}
.block-content-inner {
  @include page-margin-reset;
  max-width: $wrap-width-thin;

  margin-left: 0;
}
